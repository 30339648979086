<template>
  <!--<PageHeader :title="title" :items="items" />-->

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div>
          <img
            width="100%"
            v-if="loading"
            :src="'./images/loader.gif'"
            class="mr-2"
            style="margin-top: -5px;"
          />
        </div>
        <div class="col-12 mt-2" v-if="!loading">
          <div>
            <b-modal ref="my-modal" hide-footer title="Confirmation">
              <div class="d-block">
                <h4>{{ this.modalBodyText }}</h4>
              </div>
              <div style="float: right;">
                <button
                  type="button"
                  ref="kt_login_signin_submit"
                  class="btn btn-danger mr-3"
                  @click="changeStatus()"
                >
                  Yes
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="hideModal"
                >
                  No
                </button>
              </div>
            </b-modal>
          </div>
          <template v-if="isShowModel">
            <VerficationModal
              :show="showModal"
              :userObj="userObj"
              :actionType="actionType"
              @changeStatus="singlechangeStatus"
              @deleteRow="deleteRow"
              @closeModal="closeModal"
            />
          </template>

          <div
            v-if="alert"
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            class="alert alert-success"
          >
            {{ alertText }}
          </div>

          <router-link to="/adduser">
            <button class="btn btn-success btn btn-success rounded-pill mb-2">
              Add
            </button>
          </router-link>
          <button
            style="float: right; margin-left: 12px"
            class="btn btn-danger btn btn-danger rounded-pill mb-2"
            data-toggle="modal"
            data-target="#modalId"
            @click="confirmationBulkAction('delete')"
          >
            Delete
          </button>

          <button
            style="float: right;margin-left: 12px"
            class="btn btn-info btn btn-info rounded-pill mb-2"
            @click="confirmationBulkAction('deactive')"
          >
            Deactive
          </button>

          <button
            style="float: right;margin-left: 12px"
            class="btn btn-primary btn btn-primary rounded-pill mb-2"
            id="add-loan-btn"
            @click="confirmationBulkAction('active')"
          >
            Active
          </button>
          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Customers</h4>
          </div>
        </div>

        <div class="card-body" v-if="!loading">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>

          <div class="table-responsive mb-0">
            <b-table
              :items="loans"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :select-mode="selectMode"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              ref="selectableTable"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
              empty-text="No record found"
              empty-filtered-text="No search result found"
              @filtered="onFiltered"
            >
              <template #head(id)>
                <input v-model="isSelectAll" type="checkbox" />
              </template>
              <template v-slot:cell(id)="loans">
                <input
                  type="checkbox"
                  class="new"
                  :value="loans.item.id"
                  v-model="checkedNames"
                />
              </template>

              <template v-slot:cell(status)="loans">
                <i
                  v-if="loans.item.status == '1'"
                  style="color:green;cursor: pointer;"
                  class="fas fa-check"
                  @click="singlActiveDeactive(loans)"
                ></i
                >&nbsp;
                <i
                  v-if="loans.item.status == '0'"
                  style="color:red;cursor: pointer;"
                  class="fas fa-times"
                  @click="singlActiveDeactive(loans)"
                ></i>
              </template>

              <template v-slot:cell(actions)="loans">
                <i
                  class="fas fa-edit"
                  style="color:#4e4e63;cursor: pointer;"
                  @click="editUser(loans)"
                ></i
                >&nbsp;
                <i
                  style="color:red;cursor: pointer;"
                  class="fas fa-trash-alt"
                  @click="deleteUser(loans)"
                ></i>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="rows"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import VerficationModal from "./VerficationModal";
import axios from "axios";

export default {
  page: {
    title: "Collateral",
  },

  components: {
    VerficationModal,
  },
  data() {
    return {
      modes: ["multi", "single", "range"],
      loans: [],
      selectMode: "multi",
      alert: false,
      loading: true,
      alertText: "",
      isModel: false,
      modalBodyText: "",
      actionType: "",
      modalTitle: "",
      buttonStatus: "",
      title: "Users",
      showModal: false,
      checkedNames: [],
      customerId: "",
      userObj: {},
      isShowModel: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      showDeleteModal: false,
      sortDesc: true,
      isSelectAll: false,
      fields: [
        { key: "id", label: "Select" },
        { key: "username", label: "UserName", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "mobile", label: "Mobile", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "actions", sortable: true },
      ],
    };
  },

  computed: {
    rows() {
      return this.loans.length;
    },
  },

  watch: {
    isSelectAll: function(val) {
      if (val) {
        this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
          (x) => x.id
        );
      } else {
        this.checkedNames = [];
      }
    },
  },

  methods: {
    confirmationBulkAction(status) {
      if (this.checkedNames.length > 0) {
        if (status == "delete") {
          this.status = "delete";
          this.modalBodyText = "Do You Want To Delete This Record?";
        }
        if (status == "active") {
          this.status = "active";
          this.modalBodyText = "Do You Want To Activate This Record?";
        }
        if (status == "deactive") {
          this.status = "deactive";
          this.modalBodyText = "Do You Want To Deactivate This Record?";
        }
        this.$refs["my-modal"].show();
      } else {
        this.alert = true;
        this.alertText = "Please Select checkbox";
        this.hideAlert();
      }
    },

    successMessage(status) {
      if (status == "delete") {
        return (this.alertText = "Record has been deleted successfully");
      }
      if (status == "active") {
        return (this.alertText = "Record has been updated Successfully");
      }
      if (status == "deactive") {
        return (this.alertText = "Record has been pdated Successfully");
      }
    },
    closeModal(value) {
      this.isShowModel = value;
    },
    deleteUser(object) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = "delete";
    },

    deleteRow(getUser) {
      let index = this.loans.findIndex((x) => x.id === getUser.item.id);
      if (index !== -1) {
        this.loans.splice(index, 1);
      }

      this.alert = true;
      this.alertText = "Record has been deleted successfully";
      this.hideAlert();
    },

    singlechangeStatus(getUser) {
      let index = this.loans.findIndex((x) => x.id === getUser.id);
      if (index !== -1) {
        this.loans[index].status = getUser.status;
      }
      this.alert = true;
      this.alertText = "Record Updated Successfully";
      this.hideAlert();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },
    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },

    editUser(object) {
      localStorage.setItem("editUser", JSON.stringify(object.item));
      this.$router.push({ name: "edituser" });
    },
    singlActiveDeactive(object) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      if (this.userObj.item.status == "1") {
        this.actionType = "active";
      }
      if (this.userObj.item.status == "0") {
        this.actionType = "deactive";
      }
    },
    addClassToButton() {
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    changeStatus() {
      try {
        this.addClassToButton();
        this.showDeleteModal = true;
        const data = { status: this.status, bluckid: this.checkedNames };
        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/user-change-status", data, {
          // headers,
        }).then((response) => {
          if (response.data.code == 200) {
            this.$refs["my-modal"].hide();
            this.alert = true;
            this.successMessage(this.status);
            this.checkedNames = [];
            this.hideAlert();
            this.getAllCustomer();
            this.isSelectAll = false;
          }
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getAllCustomer() {
      try {
        axios.get(this.$path + "/user-list").then((response) => {
          this.loans = response.data.data;
          this.loading = false;
          if ("alertmessage" in localStorage) {
            this.alert = true;
            this.alertText = JSON.parse(localStorage.getItem("alertmessage"));
            this.hideAlert();
            localStorage.removeItem("alertmessage");
          }
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    changeVerfiedtext(loans) {
      if (loans.item.status == 1) {
        return "Active";
      } else {
        return "Deactive";
      }
    },
    changeClass(loans) {
      if (loans.item.status == 1) {
        return "btn btn-info btn-sm";
      } else {
        return "btn btn-primary btn-sm";
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addLoan() {
      this.$router.push({ name: "add-loan", params: { is_edit: false } });
    },

    editLoan(loan) {
      this.$router.push({
        name: "add-loan",
        params: { is_edit: true, loan_id: loan.item.id },
      });
    },
    // getAllCustomer(){
    //   ApisLists.getCustomers()
    //   .then(loans => {
    //    this.loans=loans;
    //   }).catch(error=>console.log(error))
    //     .finally(()=>{
    //     })
    // },
  },
  mounted() {
    document.title = "Customers";
    localStorage.removeItem("editUser");
    this.getAllCustomer();
    this.totalRows = this.loans.length;
    // this.getAllCustomer()
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
</style>
